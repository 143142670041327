<template>
  <div>
    <h1>{{ $t('pages.competition.title') }}</h1>

    <v-card outlined flat>
      <v-card-text>
        <v-form ref="form">
          <date-field v-model="start" v-bind:label="$t('common.words.start')"></date-field>
          <date-field v-model="end" v-bind:label="$t('common.words.end')"></date-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn depressed color="primary" @click="fetch">
          <span>{{ $t('common.action.search') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>

    <h2>{{ $t('pages.competition.ranking') }}</h2>

    <v-simple-table v-if="fetchMixin.state === fetchMixin.STATES.IDLE">
      <thead>
      <tr>
        <th class="text-center">{{ $t('common.labels.rank') }}</th>
        <th class="text-left">{{ $t('common.labels.identifier') }}</th>
        <th class="text-left">{{ $t('common.labels.username') }}</th>
        <th class="text-left">{{ $t('common.labels.score') }}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(score, i) in scoreboard.scores.elements" :key="i">
        <td class="text-center">{{ i + 1 }}</td>
        <td class="text-left">{{ score.id }}</td>
        <td class="text-left">{{ score.username }}</td>
        <td class="text-left">{{ score.points }}</td>
        <td>
          <save-winner-form :score="{rank: i + 1, ...score}">
            <template v-slot:button="{ on, attrs }">
              <v-btn
                icon color="primary"
                v-on="on" v-bind="attrs"
              >
                <v-icon>mdi-trophy</v-icon>
              </v-btn>
            </template>
          </save-winner-form>
        </td>
      </tr>
      </tbody>
    </v-simple-table>

    <paginator
      v-if="fetchMixin.pagination"
      :pagination="fetchMixin.pagination"
      @update="updatePagination"
    />

    <competition-winners></competition-winners>
  </div>
</template>

<script>
import CompetitionService from '@/services/api/competition.service';
import FechMixin from '@/mixins/fech.mixin';
import Paginator from '@/components/util/paginator.vue';
import DateField from '@/components/util/DateField.vue';
import Utils from '@/utils/utils';
import SaveWinnerForm from '@/components/competition/SaveWinnerForm.vue';
import CompetitionWinners from '@/components/competition/CompetitionWinners.vue';

const today = new Date();
const startDefault = Utils.formatDate(new Date(today.getFullYear(), today.getMonth(), 1));
const endDefault = Utils.formatDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));

export default {
  name: 'competition',
  components: {
    CompetitionWinners,
    SaveWinnerForm,
    DateField,
    Paginator,
  },
  mixins: [FechMixin],
  data: () => ({
    dialog: false,
    start: startDefault,
    end: endDefault,
    scoreboard: null,
  }),
  methods: {
    fetchScoreboard() {
      const pagination = this.fetchMixin.pagination || { page: 1, size: 10 };
      return CompetitionService.fetchScoreboard('user', this.start, this.end, pagination);
    },
  },
  created() {
    this.fetchMixin.config = {
      dataField: 'scoreboard',
      fetch: 'fetchScoreboard',
    };

    this.fetch();
  },
};
</script>

<style scoped>
</style>
