<template>
  <v-dialog
    min-width="250"
    :max-width="width"
    v-model="open"
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="button" :on="on" :attrs="attrs"></slot>
    </template>

    <v-card>
      <v-card-title class="headline">
        {{ $t('pages.competition.winner_selection') }}
      </v-card-title>

      <v-card-text>
        <v-simple-table>
          <thead>
          <tr>
            <th class="text-center">{{ $t('common.labels.rank') }}</th>
            <th class="text-left">{{ $t('common.labels.identifier') }}</th>
            <th class="text-left">{{ $t('common.labels.username') }}</th>
            <th class="text-left">{{ $t('common.labels.score') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="text-center">{{ score.rank }}</td>
            <td class="text-left">{{ score.id }}</td>
            <td class="text-left">{{ score.username }}</td>
            <td class="text-left">{{ score.points }}</td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>

      <v-card-text>
        <div>
          <v-form ref="winnerForm" v-model="validForm">
            <v-textarea v-bind:label="$t('common.labels.message')"
                        rows="4" auto-grow outlined
                        v-model="model.message"
                        :rules="[(v) => (v != null && v !== '') || $t('errors.enter_message')]"
            ></v-textarea>
            <v-text-field v-bind:label="$t('pages.competition.identifier')"
                          v-model="model.competitionIdentifier"
                          :rules="[(v) => (v != null && v !== '') || $t('errors.enter_contest_id')]"
            ></v-text-field>
            <v-text-field type="text"
                          v-bind:label="Note"
                          v-model="model.note">
            </v-text-field>
            <v-text-field v-bind:label="$t('pages.competition.coordinator_email')"
                          v-model="model.coordinatorEmail"
                          :rules="[
                            (v) => (v != null && v !== '') || $t('errors.enter_coordinator_email'),
                            (v) => (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/.test(v) || $t('errors.email')),
                            ]"
            ></v-text-field>
          </v-form>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" @click="close" :disabled="createMixin.state === createMixin.STATES.CREATING">
          {{ $t('common.action.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn color="primary" @click="create" :disabled="createMixin.state === createMixin.STATES.CREATING">
          {{ $t('common.action.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import createMixin from '@/mixins/create.mixin';
import CompetitionService from '@/services/api/competition.service';

export default {
  name: 'save-winner-form',
  mixins: [createMixin],
  props: {
    width: { default: 1000 },
    score: { type: Object, required: true },
  },
  data: () => ({
    open: false,
    validForm: false,
    model: {
      message: '',
      competitionIdentifier: '',
      coordinatorEmail: '',
      note: '',
    },
  }),
  computed: {
    ...mapGetters({ email: 'auth/email' }),
  },
  methods: {
    submit() {
      this.$refs.winnerForm.validate();
      if (!this.validForm) return;

      this.create();
    },
    save() {
      return CompetitionService.saveWinner({ accountId: this.score.id, ...this.model });
    },
    close() {
      this.open = false;
    },
  },
  created() {
    this.model.coordinatorEmail = this.email;
    this.createMixin.config = {
      create: 'save',
      callback: 'close',
    };
  },
};
</script>

<style scoped>
.v-card-title {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
</style>
