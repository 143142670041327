<template>
  <div>
    <h2>{{ $t('pages.competition.selected_winners') }}</h2>

    <v-form ref="form" @submit.prevent="fetch">
      <v-card outlined flat>
        <v-card-text>
          <v-text-field v-model="competitionIdentifier"
                        v-bind:label="$t('pages.competition.identifier')"></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn depressed @click="clear" :disabled="loading">
            <span>{{ $t('common.action.reinitialize') }}</span>
          </v-btn>
          <v-btn depressed color="primary" @click="fetch" :disabled="loading">
            <span>{{ $t('common.action.search') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <v-simple-table>
      <thead>
      <tr>
        <th class="text-center">{{ $t('common.labels.id') }}</th>
        <th class="text-left">{{ $t('common.labels.id') }} {{ $t('common.labels.winner') }}</th>
        <th class="text-left">{{ $t('common.labels.message') }}</th>
        <th class="text-left">{{ $t('pages.competition.identifier') }}</th>
        <th class="text-left">{{ $t('common.labels.note') }}</th>
        <th class="text-left">{{ $t('pages.competition.coordinator_email') }}</th>
        <th class="text-left">{{ $t('common.labels.state') }}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(winner, i) in winners" :key="i">
        <td class="text-left">{{ winner.id }}</td>
        <td class="text-left">{{ winner.accountId }}</td>
<!--        TODO : Show collapsable multiline text -->
        <td class="text-left">{{ winner.message }}</td>
        <td class="text-left">{{ winner.competitionIdentifier }}</td>
        <td class="text-left">{{ winner.note || '-' }}</td>
        <td class="text-left">{{ winner.coordinatorEmail }}</td>
        <td class="text-left">{{ winner.state }}</td>
        <td>
          <confirm-button width="550px" v-if="winner.state === 'CLAIMED'" @click="createWithParam(winner.id)" :disabled="loading">
            <template v-slot:title>
              {{ $t('pages.competition.mark_reward_as_sent') }}
            </template>
            <template v-slot:button="{ on, attrs }">
              <v-btn
                icon color="primary"
                v-on="on" v-bind="attrs"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </template>
          </confirm-button>
          <confirm-button width="400px" @click="remove(winner.id)" :disabled="loading">
            <template v-slot:title>
              {{ $t('pages.competition.delete_validation_1') }}<br/>
              {{ $t('pages.competition.delete_validation_2') }}
            </template>

            <template v-slot:message>
              {{ $t('pages.competition.delete_validation_message') }}
            </template>
            <template v-slot:button="{ on, attrs }">
              <v-btn
                icon color="error"
                v-on="on" v-bind="attrs"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </confirm-button>
        </td>
      </tr>
      </tbody>
    </v-simple-table>

    <paginator
      v-if="fetchMixin.pagination"
      :pagination="{...fetchMixin.pagination}"
      @update="updatePagination"
    />
  </div>
</template>

<script>
import fetchMixin from '@/mixins/fech.mixin';
import CompetitionService from '@/services/api/competition.service';
import deleteMixin from '@/mixins/delete.mixin';
import Paginator from '@/components/util/paginator.vue';
import ConfirmButton from '@/components/util/ConfirmButton.vue';
import createMixin from '@/mixins/create.mixin';

export default {
  name: 'competition-winners',
  components: { ConfirmButton, Paginator },
  mixins: [fetchMixin, createMixin, deleteMixin],
  data: () => ({
    winners: null,
    competitionIdentifier: '',
  }),
  computed: {
    loading() {
      return this.fetchMixin.state === this.fetchMixin.STATES.LOADING || this.deleteMixin.state === this.deleteMixin.STATES.LOADING;
    },
  },
  methods: {
    clear() {
      this.competitionIdentifier = '';
      this.fetch();
    },
    fetchWinners() {
      const pagination = this.fetchMixin.pagination || {
        page: 1,
        size: 10,
      };
      return CompetitionService.fetchWinners(this.competitionIdentifier || null, pagination);
    },
    rewardSent(id) {
      return CompetitionService.rewardSent(id);
    },
    deleteWinner(id) {
      return CompetitionService.deleteWinner(id);
    },
  },
  created() {
    this.fetchMixin.config = {
      dataField: 'winners',
      fetch: 'fetchWinners',
    };

    this.createMixin.config = {
      create: 'rewardSent',
      callback: 'fetch',
    };

    this.deleteMixin.config = {
      delete: 'deleteWinner',
      callback: 'fetch',
    };

    this.fetch();
  },
};
</script>

<style scoped>

</style>
