import AxiosApiService from '@/services/axios/axios.api.service';

const CompetitionService = {
  fetchScoreboard(role, start, end, pagination) {
    return AxiosApiService.get('/competition', true, {
      role,
      start,
      end,
      queryProfileScore: false,
      ...pagination,
    });
  },
  saveWinner(command) {
    return AxiosApiService.post('/competition/winners', command);
  },
  fetchWinners(competitionIdentifier, pagination) {
    const query = { ...pagination };
    if (competitionIdentifier) {
      query.competitionIdentifier = competitionIdentifier;
    }
    return AxiosApiService.get('/competition/winners', true, query);
  },
  deleteWinner(id) {
    return AxiosApiService.delete(`/competition/winners/${id}`);
  },
  rewardSent(id) {
    return AxiosApiService.post(`/competition/rewards/${id}/sent`);
  },
};

export default CompetitionService;
